import React, {Component} from "react";
import Api from "../../../libraries/api";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import {history} from "../../../shared/configure-store";
import DateFormat from "../../../libraries/data-format-helper";

export default class TransponderWaitingLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            perPage: 50,
            currentPage: 0,
            currentPageTable: 0,
            totalData: 0,
            search: '',
        };
        this.getTransponderWaiting();
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getTransponderWaiting() {
        let currentPage = this.state.currentPage + 1;
        let url = "/transponder-waiting?perPage=" + this.state.perPage + "&pageNumber=" + currentPage + "&search=" + this.state.search;
        Api.get(url, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["TransponderWaiting"],
                loading: false,
                totalData: resp["data"]["meta"]["totalData"]
            });
        });
    }

    handleClose() {
        this.setState({showDetail: false})
    }

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.setState({
                rows: [],
                currentPage: 0,
                currentPageTable: 0,
                loading: true,
            }, () => {
                this.getTransponderWaiting();
            });
        }
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.getTransponderWaiting();
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 0,
            currentPageTable: 0,
            loading: true,
        }, () => {
            this.getTransponderWaiting();
        });
    };

    render() {
        return (
            <div className="row">
                <div className="col-12 card">
                    <div className="row align-items-center">
                        <div className="col-md-4 search-bar">
                            <TextField
                                type="text"
                                id={"adornment-search-waiting"}
                                name="search"
                                placeholder="Search by name, email, phone number or account number"
                                onChange={(e) => this.handleChange(e, 'search')}
                                onKeyPress={this.handleKeyPressSearch}
                                value={this.state.search}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                aria-label="Search button"
                                                onClick={this.handleClickSearch}
                                            >
                                                <i className="fas fa-search"> </i>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account Number</TableCell>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Register Date</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <TransponderWaitingTable rows={this.state.rows}
                                                             handlePurchaseDelivered={this.handlePurchaseDelivered}/>
                                )
                            }
                        </Table>
                    </div>
                    <div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class TransponderWaitingTable extends Component {

    render() {
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={i} onClick={() => history.push('/accounts/detail/' + item.userId)}>
                            <TableCell>{item.userId}</TableCell>
                            <TableCell>{item.fullName}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>{DateFormat.dateTime(item.createdAt)}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}